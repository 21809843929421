import { useState, useEffect } from "react";
import AudioForNft from "components/AudioForNft";
import { BACKEND_URL } from "config";
import ButtonPlayMusicRunningContainer from "containers/ButtonPlayMusicRunningContainer";
import { useNavigate } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import axios from "axios";
import { useAppSelector } from "app/hooks";
import { selectCurrentUser } from "app/reducers/auth.reducers";
import isEmpty from "utilities/isEmpty";
import RemainingTimeNftCard from "components/RemainingTimeNftCard";

const MusicListItem = (props: any) => {

    const [item, setItem] = useState({});
    const [muteMusic, setMuteMusic] = useState(false);
    const navigate = useNavigate();
    const [DEMO_NFT_ID] = useState(nanoid());
    const currentUsr = useAppSelector(selectCurrentUser);

    useEffect(() => {
      if(props && props.item) setItem(props.item);
      if(props && props.muteMusic) setMuteMusic(props.muteMusic);
    }, [])
    
    const renderIcon = (state: any, forPlatformPlay = false) => {
      if (forPlatformPlay === false) {
        if (!state) {
          return (
            <svg className="ml-0.5 w-9 h-9" fill="currentColor" viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M18.25 12L5.75 5.75V18.25L18.25 12Z"
              ></path>
            </svg>
          );
        }

        return (
          <svg className=" w-9 h-9" fill="none" viewBox="0 0 24 24">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M15.25 6.75V17.25"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M8.75 6.75V17.25"
            ></path>
          </svg>
        );
      }
      else {
        if (!state) {
          return (
            <div className="w-12 h-12 p-4 rounded-full drop-shadow-md bg-gradient-to-r from-green-400 to-blue-500">
              <img
                src={`${window.location.origin}/images/icons/play-button-svgrepo-com.svg`}
                alt=""
                className="filter invert"
              />
            </div>
          );
        }

        return (
          <div className="w-12 h-12 p-4 rounded-full drop-shadow-md bg-gradient-to-r from-green-400 to-blue-500">
            <img
              src={`${window.location.origin}/images/icons/pause-icon.svg`}
              alt=""
              className="filter invert mt-[-3px]"
            />
          </div>
        );
      }
    };

    const renderListenButtonDefault = (state: any, forPlatformPlay = false) => {
        return (
        <div
            className={`w-14 h-14 flex items-center justify-center rounded-full bg-neutral-50 text-primary-500 cursor-pointer`}
        >
            {renderIcon(state, forPlatformPlay)}
        </div>
        );
    };

    const plusPlayCount = async (itemId: string) => {
      if(props.setCustomPlayingId) props.setCustomPlayingId(itemId || DEMO_NFT_ID);
      await axios
      .post(
          `${BACKEND_URL}/api/playhistory/createPlayHistory`,
          { itemId: itemId || "", userId: currentUsr?._id || "635808844de50f7f88494338" },
          {
          headers: {
              "x-access-token": localStorage.getItem("jwtToken"),
          },
          }
      )
      .then((result) => { })
      .catch(() => { });
    };    
 
    return (
        <div
            className="flex px-4 py-2 my-1 border rounded"
            >
            <AudioForNft
              src={
              (item as any)?.musicURL
                  ? `${BACKEND_URL}/uploads/${(item as any)?.musicURL}`
                  : undefined
              }
              nftId={(item as any)?._id || DEMO_NFT_ID}
              muted={muteMusic}
              itemLength={(item as any)?.timeLength || 0}
            />
            <div className="flex items-center justify-start w-8/12">
              <button className="w-5">
                <ButtonPlayMusicRunningContainer
                    className=""
                    nftId={(item as any)?._id || DEMO_NFT_ID}
                    renderDefaultBtn={() =>
                    renderListenButtonDefault(undefined)
                    }
                    renderPlayingBtn={() =>
                    renderListenButtonDefault("playing")
                    }
                    renderLoadingBtn={() =>
                    renderListenButtonDefault("loading")
                    }
                    increaseFunc={plusPlayCount}
                    item={item}
                />
              </button>
              <button
                className="w-8 p-[2px]"
                style={{ margin: "0 10px 0 30px" }}
              >
                <img
                    src="/images/playlist.svg"
                    className="w-10"
                    alt="P"
                    onClick={() => navigate(`/nft-detail/${(item as any)?._id || ""}`)}
                />
              </button>
              <img
                  src={`${BACKEND_URL}/uploads/${(item as any)?.logoURL || ""
                      }`}
                  className="ml-[30px] cursor-pointer"
                  width={50}
                  height={50}
                  alt=""
                  onClick={() => navigate(`/nft-detail/${(item as any)?._id || ""}`)}
              />
              <div className="pl-3  cursor-pointer"  onClick={() => navigate(`/nft-detail/${(item as any)?._id || ""}`)}>
                <div className="text-sm font-medium text-gray-700">
                    {`${(item as any)?.name || ""}`}
                </div>
                <div className="text-xs text-gray-500">
                    {`${(item as any)?.description || ""}`}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end w-4/12 rounded">
              <div className="mr-5 text-xs">
                <span> {(item as any)?.playCount || 0} plays</span>
              </div>
            </div>
        </div>
    )
}

export default MusicListItem;