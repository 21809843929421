import React, { FC } from "react";
import MainNav1 from "./MainNav1";

export interface Header2Props {}

const Header2: FC<Header2Props> = () => {
  return (
    <div className="relative w-full z-1 nc-Header2 ">
      <MainNav1 isTop={true} />
    </div>
  );
};

export default Header2;
