import React from "react";
import HeaderLogged from "components/Header/HeaderLogged";
import Header2 from "components/Header/Header2";
import { useAppSelector } from "app/hooks";
import isEmpty from "utilities/isEmpty";
import { selectCurrentUser } from "app/reducers/auth.reducers";

const SiteHeader = () => {
  const currentUsr = useAppSelector(selectCurrentUser);
  
  return (isEmpty(currentUsr) || isEmpty(currentUsr?.address)) ? <Header2 /> : <HeaderLogged />;
};

export default SiteHeader;
