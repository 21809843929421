import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Navigation from "shared/Navigation/Navigation";
import Web3 from "web3";
import Web3Modal from "web3modal";
import {
  changeChainId,
  changeWalletAddress,
  changeGlobalProvider,
  selectCurrentWallet,
  selectCurrentChainId,
  changeWalletStatus
} from 'app/reducers/auth.reducers';
import { providerOptions } from "InteractWithSmartContract/providerOptions";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { chains, platformChainIds } from "config";
import { changeNetwork } from "InteractWithSmartContract/interact";
import SelectNetworkDropdown from "./SelectNetworkDropdown";

export interface MainNav2LoggedProps {}

export const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: false,
  disableInjectedProvider: false,
  providerOptions,
});

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const globalAddress = useAppSelector(selectCurrentWallet);
  const globalChainId = useAppSelector(selectCurrentChainId);
  const [connected, setConnected] = useState(false);
  const [provider, setProvider] = useState({});
  const [compressedAccount, setCompressedAccount] = useState("");

  useEffect(() => {
    if (globalAddress && globalChainId) {
      setConnected(true);
      dispatch(changeWalletStatus(true));
    } else {
      setConnected(false);
      dispatch(changeWalletStatus(false));
    }
  }, [globalAddress, globalChainId]);

  const onClickConnectWallet = async () => {
    try {
      var provider = await web3Modal.connect();
      var web3 = new Web3(provider);
      var accounts = await web3.eth.getAccounts();
      var chainId = await web3.eth.getChainId();

      setProvider(provider);
      dispatch(changeGlobalProvider(provider));
      if (accounts[0]) {
        dispatch(changeWalletAddress(accounts[0]));
        setConnected(true);
        dispatch(changeWalletStatus(true));
      } else {
        dispatch(changeWalletAddress(""));
        setConnected(false);
        dispatch(changeWalletStatus(false));      
      }
      dispatch(changeChainId(chainId));        
      if(chainId !== platformChainIds[0] && chainId !== platformChainIds[1])
      {
        let changed = await changeNetwork(provider, platformChainIds[0]);
        if((changed as any).success === true) {      
          provider = await web3Modal.connect(); 
          setProvider(provider);
          dispatch(changeGlobalProvider(provider));          
          dispatch(changeChainId(platformChainIds[0]));
        }
        else {
          toast.error(`Please reconnect your wallet to ${(chains as any)[platformChainIds[0]].name || ""} or ${(chains as any)[platformChainIds[1]].name || ""} and try again.`);                              
          dispatch(changeWalletAddress(""));
          setConnected(false);
          dispatch(changeWalletStatus(false));      
        }
      }
    } catch (error) {
      console.error(error);
      setConnected(false);
      dispatch(changeWalletStatus(false));      
      dispatch(changeWalletAddress(""));
    }
  };

  useEffect(() => {
    if (globalAddress) {
      let accountStr = globalAddress.toString();
      setCompressedAccount(
        accountStr.substring(0, 5) +
          "..." +
          accountStr.substring(accountStr.length - 4, accountStr.length)
      );
    } else {
      setCompressedAccount("");
    }
  }, [globalAddress]);

  const onClickDisconnect = async () => {   
    try {
      if(provider && (provider as any).close) {
        await (provider as any).close();
    
        // If the cached provider is not cleared,
        // WalletConnect will default to the existing session
        // and does not allow to re-scan the QR code with a new wallet.
        // Depending on your use case you may want or want not his behavir.
        await web3Modal.clearCachedProvider();
        setProvider({});
      }
    } catch (e) {}
    setConnected(false);
    dispatch(changeChainId(0));
    dispatch(changeWalletAddress(""));
    dispatch(changeGlobalProvider({}));    
    dispatch(changeWalletStatus(false));
  };

  useEffect(() => {
    if ((provider as any ).on) {
      const handleAccountsChanged = (accounts:Array<any>) => {
        if (accounts[0]) {
          dispatch(changeWalletAddress(accounts[0]));
          setConnected(true);
          dispatch(changeWalletStatus(true));
        } else {
          dispatch(changeWalletAddress(""));
          setConnected(false);
          dispatch(changeWalletStatus(false));
        }
      };

      const handleChainChanged = (chainId: number) => {
        dispatch(changeChainId(chainId));
      };

      const handleDisconnect = () => {
        onClickDisconnect();
      };

      (provider as any).on("accountsChanged", handleAccountsChanged);
      (provider as any).on("chainChanged", handleChainChanged);
      (provider as any).on("disconnect", handleDisconnect);

      return () => {
        if ((provider as any).removeListener) {
          (provider as any).removeListener("accountsChanged", handleAccountsChanged);
          (provider as any).removeListener("chainChanged", handleChainChanged);
          (provider as any).removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider, dispatch]);

  return (
    <div className={`nc-MainNav2Logged relative z-10 ${"onTop "}`}>
      <div className="container relative flex items-center justify-between py-5 space-x-4 xl:space-x-8">
        <div className="flex items-center justify-start flex-grow space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
          {/* <div className="flex-grow hidden max-w-xs sm:block">
            <form action="" method="POST" className="relative">
              <Input
                type="search"
                placeholder="Search items"
                className="w-full pr-10"
                sizeClass="h-[42px] pl-4 py-3"
              />
              <span className="absolute -translate-y-1/2 top-1/2 right-3 text-neutral-500">
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <input type="submit" hidden value="" />
            </form>
          </div> */}
        </div>
        <div className="flex items-center justify-end flex-shrink-0 space-x-1 text-neutral-700 dark:text-neutral-100">
          <div className="items-center hidden space-x-2 xl:flex">
            <Navigation />
            <div className="hidden h-6 border-l sm:block border-neutral-300 dark:border-neutral-6000"></div>
            <div className="flex">
              {/* <SwitchDarkMode /> */}
              {/* <NotifyDropdown /> */}
            </div>
            <ButtonPrimary
              onClick={() => navigate("/page-upload-item")}
              sizeClass="px-4 py-2 sm:px-5"
            >
              Create
            </ButtonPrimary>            
            <SelectNetworkDropdown />
            <ButtonPrimary
              sizeClass="px-4 py-2 sm:px-5"
              onClick={() => {
                if (connected === false) onClickConnectWallet();
                else onClickDisconnect();
              }}
            >
              {connected === false && "Connect Wallet"}
                {connected === true && compressedAccount}
            </ButtonPrimary>
            <AvatarDropdown />
          </div>
          <div className="flex items-center space-x-3 xl:hidden">
            {/* <NotifyDropdown /> */}
            <SelectNetworkDropdown />
            <AvatarDropdown />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2Logged;
