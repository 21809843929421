import { Helmet } from "react-helmet";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { BACKEND_URL, CATEGORIES } from "config";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { selectCurrentUser } from "app/reducers/auth.reducers";
import isEmpty from "utilities/isEmpty";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import ButtonPlayMusicRunningContainer from "../ButtonPlayMusicRunningContainer";
import { nanoid } from "@reduxjs/toolkit";
import AudioForNft from "components/AudioForNft";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import { selectCategorySummary } from "app/reducers/nft.reducers";
import { Checkbox } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import VideoForNft from "components/VideoForNft";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import mediaRunning, { selectCurrentMediaRunning } from "app/reducers/mediaRunning";
import { signDaiPermit, signERC2612Permit } from 'eth-permit';
import { ethers } from "ethers";
import MusicListItem from "./MusicListItem";

const musicPlayerBannerImages = [
  `${window.location.origin}/images/happy-man-listening-music-from-mobile-phone.jpg`,
  `${window.location.origin}/images/man-listening-to-music.jpg`
]

const Myprofile = (props: any) => {
  const navigate = useNavigate();
  const currentUsr = useAppSelector(selectCurrentUser);
  const handleToggleLeftSidebar = () => setShowLeftSidebar(!showLeftSidebar);
  const handleToggleRightSidebar = () => setShowRightSidebar(!showRightSidebar);
  const [showModal, setShowModal] = React.useState(false);
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [popularSellers, setPopularSellers] = useState(Array<any>);
  const [isFollowing, setIsFollowing] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [settings, setSlickSettings] = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 30000
  });
  const [DEMO_NFT_ID] = useState(nanoid());
  const [detailedPL, setDetailedPlayList] = useState([]);
  const [topListeners, setTopListeners] = useState([]);
  const [popularItems, setPopularItems] = useState([]);
  const [keywordForSearch, setKeywordForSearch] = useState("");
  const [consiseringCategory, setConsideringCategory] = useState(-1);
  const [itemsFoundByKeyword, setItemsFoundByKeyword] = useState([]);
  const [itemsFoundByCategories, setItemsFoundByCategories] = useState([]);
  const globalCategorySummary = useAppSelector(selectCategorySummary);
  const [playlistIdsOfUser, setPlaylistIdsOfuser] = useState([]);
  const [selectedPLName, setSelectedPLName] = useState("");
  const [selectedPLId, setSelectedPLId] = useState("");
  const [itemsOfaPL, setItemsOfaPL] = useState([]);
  const [allPlayableItems, setAllPlayableItems] = useState([]);
  const [nameOfNewPL, setNameOfNewPL] = useState("");
  const [currentPlayingMusicIndex, setCurrentPlayingMusicIndex] = useState(0);
  const [muteMusic, setMuteMusic] = useState(false);
  const location = useLocation();
  const [fileLength, setFileLength] = useState("00:00");
  const [secondsLen, setSecondsLen] = useState(0);
  const currentMediaRunning = useAppSelector(selectCurrentMediaRunning);
  const [playerProgressInterval, setPlayerProgressInterval] = useState(0);
  const [widthOfProgressBar, setWidthOfProgressBar] = useState("0%");
  
  useEffect(() => {
    if(currentMediaRunning)
    {
      if(currentMediaRunning.state === "playing")
      {
        if(Number(playerProgressInterval)<= 0)
        {
          let cntNum = 0;
          let newInterval = setInterval(() => {
            if(currentMediaRunning.nftId  && currentMediaRunning.nftId !== "")
            {
              console.log(cntNum)
              cntNum = cntNum + 0.2;
              let fileLength = secondsLen>0? secondsLen: 60;
              if(cntNum <= fileLength) 
              {
                console.log( Number(cntNum * 100 / fileLength).toString()+"%" );
                setWidthOfProgressBar( Number(cntNum * 100 / fileLength).toString()+"%" )
              }
            }
          }, 200);
          setPlayerProgressInterval(Number(newInterval));     
        }   
      }
      else if(Number(playerProgressInterval)>0) 
      {
        clearInterval(playerProgressInterval);
        setPlayerProgressInterval(0);
        setWidthOfProgressBar( "0%" )
      }
    }
  },  [currentMediaRunning, currentPlayingMusicIndex]);

  const updateFileLength = () => {
    var x = document.getElementById(`tone`) as any;
    if(x && typeof x === 'object')
    {
      let secondLen = 0;
      if(isEmpty(x) === false) secondLen = (currentMediaRunning.item as any)?.timeLength>0? (currentMediaRunning.item as any)?.timeLength :  x?.duration; setSecondsLen(secondLen);
      let dateObj = new Date(secondLen * 1000);
      // let hours = isNaN(dateObj.getUTCHours()) === false? dateObj.getUTCHours() : 0;
      let minutes = isNaN(dateObj.getUTCMinutes()) === false? dateObj.getUTCMinutes() : 0;
      let seconds = isNaN(dateObj.getSeconds()) === false? dateObj.getSeconds(): 0;
      // let timeString = hours.toString().padStart(2, '0') + 'h:' + 
      let timeString = 
      minutes.toString().padStart(2, '0') + ':' + 
      seconds.toString().padStart(2, '0') ;
      setFileLength(timeString);

      x.onloadedmetadata = function() {
          let secondLen = 0;
          if(isEmpty(x) === false) secondLen = (currentMediaRunning.item as any)?.timeLength>0? (currentMediaRunning.item as any)?.timeLength :  x?.duration;
          if((currentMediaRunning.item as any)?.timeLength === 0 && secondLen>0) {
            axios.post(
              `${BACKEND_URL}/api/item/updateTimeLength`,
              {
                itemId: (currentMediaRunning.item as any)?._id || "",
                timeLength: secondLen
              }
            )
            .then((docs) => {})
            .catch((error) => {});
          }
          setSecondsLen(secondLen);
          let dateObj = new Date(secondLen * 1000);
          // let hours = isNaN(dateObj.getUTCHours()) === false? dateObj.getUTCHours() : 0;
          let minutes = isNaN(dateObj.getUTCMinutes()) === false? dateObj.getUTCMinutes() : 0;
          let seconds = isNaN(dateObj.getSeconds()) === false? dateObj.getSeconds(): 0;
          // let timeString = hours.toString().padStart(2, '0') + 'h:' + 
          let timeString = 
          minutes.toString().padStart(2, '0') + ':' + 
          seconds.toString().padStart(2, '0') ;
          setFileLength(timeString);
      };          
    }
  };

  useEffect(() => {
    updateFileLength();
  },  [currentPlayingMusicIndex, currentMediaRunning])

  useEffect(() => {
      getPopularItems(5);
      getPopuarSellers(5);
      if ((currentUsr as any)?._id !== undefined && isEmpty(currentUsr?._id) === false) {
        getUserDetailedPlayHistory((currentUsr as any)?._id);
        getPlaylistOfUser();
        getAllPlayableItems();
      } else {
        toast.info("You must be logged in to see or create a playlist. Please log in");
      }
  }, []);

  useEffect(() => {
    getItemsByCategoryValue(consiseringCategory);
  }, [consiseringCategory]);

  useEffect(() => {
    getItemsOfaPL();
  }, [selectedPLId])
  

  const getItemsOfaPL = async () => {
    var itemIdsOfPL = [];
    itemIdsOfPL = (playlistIdsOfUser.find(pl => (pl as any)?._id === selectedPLId) as any)?.items || [];
    if (itemIdsOfPL.length > 0) {
      axios.post(
        `${BACKEND_URL}/api/item/findManyByIds`,
        {
          idArray: itemIdsOfPL
        }
      ).then((response) => {
        setItemsOfaPL(response.data.data || []);
      })
        .catch((error) => {

        })
    }
    else {
      setItemsOfaPL([]);
    }
  }

  const getPlaylistOfUser = async () => {
    if (currentUsr?._id && (currentUsr as any)?._id !== "") {
      await axios.post(
        `${BACKEND_URL}/api/playlist/getPlayListsOfUser`,
        {
          userId: currentUsr?._id || ""
        }
      )
        .then((response) => {
          setPlaylistIdsOfuser(response.data.data || []);
        })
        .catch(error => { })
    } else {
      setPlaylistIdsOfuser([]);
    }
  }

  const getItemsByCategoryValue = async (value: any) => {
    var idArray = [];
    idArray = globalCategorySummary?.length > 0 ? globalCategorySummary.find((summary, index) => summary?.category?.value == value)?.items || [] : [];
    if (idArray.length > 0) {
      axios.post(
        `${BACKEND_URL}/api/item/findManyByIds`,
        {
          idArray: idArray
        }
      ).then((response) => {
        setItemsFoundByCategories(response.data.data || []);
      })
        .catch((error) => {

        })
    }
    else {
      setItemsFoundByCategories([]);
    }
  }

  const getPopularItems = async (limit: number) => {
    axios
      .post(
        `${BACKEND_URL}/api/item/getPopularItems`,
        limit > 0 ? { limit: limit } : 10
      )
      .then((response) => {
        let temp = response.data.data;
        setPopularItems(temp);
        let idArr = [];
        for(let idx=0; idx<temp.length; idx++) idArr.push(temp[idx]?.creator?._id || "");
        getArtistsOfPopularMusic(idArr);
      })
      .catch((error) => {
        setPopularItems([]);
      });
  };

  const getArtistsOfPopularMusic = async (idArr: Array<string>) => {
    axios
      .post(
        `${BACKEND_URL}/api/users/getTopArtists`,
        {idArr: idArr}
      )
      .then((response) => {
        let temp = response.data.data;
        console.log("getArtistsOfPopularMusic : ", temp);
        if(temp.length>0)
        {
          let tempIsFollowing = isFollowing;
          for (let idx = 0; idx < temp?.length; idx++) {
            if (temp[idx]?.follows.includes(currentUsr?._id) === true) {
              tempIsFollowing = { ...tempIsFollowing, [temp[idx]?._id]: true };
            } else {
              tempIsFollowing = { ...tempIsFollowing, [temp[idx]?._id]: false };
            }
          }
          setIsFollowing(tempIsFollowing);
          //filter same _id
          let currentPopularUserList = popularSellers;
          // temp = temp.filter(y  => currentPopularUserList.map(x => (x as any)?._id !== (y as any)?._id ));
          currentPopularUserList = [...currentPopularUserList, ...temp];
          setPopularSellers(temp);
        }
      })
      .catch((error) => {
      });
  }

  const getUserDetailedPlayHistory = async (userId: string) => {
    if (isEmpty(userId)) return;
    let tempPlayList = [];
    await axios
      .post(
        `${BACKEND_URL}/api/users/findOne`,
        { userId },
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      )
      .then((result) => {
        let simplePL = result.data.data.playList || [];
        console.log("simplePL = ", simplePL);
        tempPlayList = simplePL;
        setDetailedPlayList(tempPlayList);
      })
      .catch((err) => { });
  };


  const renderIcon = (state: any, forPlatformPlay = false) => {
    if (forPlatformPlay === false) {
      if (!state) {
        return (
          <svg className="ml-0.5 w-9 h-9" fill="currentColor" viewBox="0 0 24 24">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M18.25 12L5.75 5.75V18.25L18.25 12Z"
            ></path>
          </svg>
        );
      }

      return (
        <svg className=" w-9 h-9" fill="none" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M15.25 6.75V17.25"
          ></path>
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M8.75 6.75V17.25"
          ></path>
        </svg>
      );
    }
    else {
      if (!state) {
        return (
          <div className="w-12 h-12 p-4 rounded-full drop-shadow-md bg-gradient-to-r from-green-400 to-blue-500">
            <img
              src={`${window.location.origin}/images/icons/play-button-svgrepo-com.svg`}
              alt=""
              className="filter invert"
            />
          </div>
        );
      }

      return (
        <div className="w-12 h-12 p-4 rounded-full drop-shadow-md bg-gradient-to-r from-green-400 to-blue-500">
          <img
            src={`${window.location.origin}/images/icons/pause-icon.svg`}
            alt=""
            className="filter invert mt-[-3px]"
          />
        </div>
      );
    }
  };

  const renderListenButtonDefault = (state: any, forPlatformPlay = false) => {
    return (
      <div
        className={`w-14 h-14 flex items-center justify-center rounded-full bg-neutral-50 text-primary-500 cursor-pointer`}
      >
        {renderIcon(state, forPlatformPlay)}
      </div>
    );
  };

  const plusPlayCount = async (itemId: string) => {
    await axios
      .post(
        `${BACKEND_URL}/api/playhistory/createPlayHistory`,
        { itemId: itemId || "", userId: currentUsr?._id || "" },
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      )
      .then((result) => { })
      .catch(() => { });
  };

  const toggleFollow = async (my_id: string, target_id: string) => {
    if (isEmpty(my_id) || isEmpty(target_id)) return;
    await axios
      .post(
        `${BACKEND_URL}/api/follow/toggle_follow`,
        { my_id, target_id },
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      )
      .then(async (result) => {
        let temp = isFollowing;
        temp = { ...temp, [target_id]: !(isFollowing as any)[target_id] };
        setIsFollowing(temp);
        await getPopuarSellers(10);
      })
      .catch(() => { });
  };

  const getPopuarSellers = async (limit: number) => {
    axios
      .post(
        `${BACKEND_URL}/api/users/get_popular_user_list`,
        limit > 0 ? { limit: limit } : 10
      )
      .then(async (response) => {
        let temp0 = [...response.data.data.seller, ...response.data.data.buyer];        
          if (temp0?.length > 0) {
            let tempIsFollowing = isFollowing;
            for (let idx = 0; idx < temp0?.length; idx++) {
              if (temp0[idx]?.follows.includes(currentUsr?._id) === true) {
                tempIsFollowing = { ...tempIsFollowing, [temp0[idx]?._id]: true };
              } else {
                tempIsFollowing = { ...tempIsFollowing, [temp0[idx]?._id]: false };
              }
            }
            setIsFollowing(tempIsFollowing);
          }
          setPopularSellers(temp0);
        })
        .catch((error) => {
          setTopListeners([]);
        });        
  };

  const onClickSearch = async () => {
    if (keywordForSearch && keywordForSearch !== "") {
      await axios.post(
        `${BACKEND_URL}/api/item/findByKeyword`,
        {
          keyword: keywordForSearch
        }
      )
        .then((response) => {
          setItemsFoundByKeyword(response.data.data);
        })
        .then((error) => {
        })
    } else {
      setItemsFoundByKeyword([]);
    }
  }

  const onCreateEmptyList = async () => {
    await axios.post(`${BACKEND_URL}/api/playlist/createEmptyList`,
      {
        userId: currentUsr?._id || "",
        name: nameOfNewPL || ""
      }, {
      headers:
      {
        "x-access-token": localStorage.getItem("jwtToken")
      }
    }).then((result) => {
      if (result.data.code === 0) {
        toast.success("You've successfully created a new playlist.");
        setNameOfNewPL("");
        getPlaylistOfUser();
      }
      else {
        toast.warn(result.data.message);
      }
    }).catch((err) => {
      toast.error(err);
    });
  }

  const onDeleteAPL = async (plId: string) => {
    if (plId && plId !== "") {
      await axios.post(
        `${BACKEND_URL}/api/playlist/deletePL`,
        {
          _id: plId,
          userId: (currentUsr as any)?._id || ""
        }
      )
        .then((response) => {
          if (response.data.code === 0) {
            toast.success("You 've deleted a playlist");
            getAllPlayableItems();
            getPlaylistOfUser();
          }
          else toast.error(response.data.message);
        })
        .catch(error => { });
    } else {
      toast.warn("Invalid playlist id");
    }
  }

  const onDisablePL = async (plId: string) => {
    if (plId && plId !== "") {
      await axios.post(
        `${BACKEND_URL}/api/playlist/updateEnablePlay`,
        {
          _id: plId,
          userId: (currentUsr as any)?._id || ""
        }
      )
        .then((response) => {
          if (response.data.code === 0) {
            getPlaylistOfUser();
            getAllPlayableItems();
          }
          else toast.error(response.data.message);
        })
        .catch(error => { });
    } else {
      toast.warn("Invalid playlist id");
    }
  }

  const getAllPlayableItems = async () => {
    await axios.post(
      `${BACKEND_URL}/api/playlist/findAllEnabledItems`,
      {
        userId: (currentUsr as any)?._id || ""
      }
    )
      .then(async (response: any) => {
        let allPlaybleItemIds = response.data.data || [];
        if (allPlaybleItemIds.length > 0) {
          await axios.post(
            `${BACKEND_URL}/api/item/findManyByIds`,
            {
              idArray: allPlaybleItemIds
            }
          ).then((response2) => {
            setAllPlayableItems(response2.data.data || []);
            setCurrentPlayingMusicIndex(0);
            updateFileLength();
          })
            .catch((error) => {

            })
        }
        else {
          setAllPlayableItems([]);
        }
      })
      .catch(error => { });
  }

  const onMuteMusic = () => {
    setMuteMusic(!muteMusic);
  }

  const isVideo = (item: any) => {
    return (item as any)?.musicURL?.toLowerCase().includes("mp4") ? true : false;
  }

  return (
    <div className="flex">
      <Helmet>
        <title>Music Player || Redioreum NFT Marketplace</title>
      </Helmet>
      <div className="fixed z-20 -translate-y-full lg:hidden top-1/2">
        <button
          className={`rounded-rull w-5 transition ${showLeftSidebar ? `rotate-180` : `rotate-0`
            }`}
          onClick={handleToggleLeftSidebar}
        >
          <img src={`${window.location.origin}/images/icons/right-angle-arrow-outline-icon.svg`} alt="" />
        </button>
      </div>

      <div
        className={`top-0 lg:top-auto fixed lg:relative h-screen lg:h-auto overflow-y-auto z-10 w-80 bg-white flex-shrink-0 border-r border-gray-100 transition ${showLeftSidebar
            ? `translate-x-0`
            : `-translate-x-full lg:translate-x-0`
          }`}
      >
        <p className="px-8 py-5">Browse Music</p>

        <ul>
          <li
            className={`flex items-center h-10 px-8 cursor-pointer ${activeIndex == 0 ? 'bg-teal-100' : ''}`}
            onClick={() => { setActiveIndex(0); setSelectedPLName(""); }}
          >
            <img src={`${window.location.origin}/images/icons/bestsong.png`} className="w-5 h-5" alt="" />
            <span className="ml-3">Top Songs</span>
          </li>
          <li
            className={`flex items-center h-10 px-8 cursor-pointer  ${activeIndex == 1 ? 'bg-teal-100' : ''}`}
            onClick={() => { setActiveIndex(1); setSelectedPLName(""); }}
          >
            <img src={`${window.location.origin}/images/icons/reciba.png`} className="w-5 h-5" alt="" />
            <span className="ml-3">Find Music</span>
          </li>
          <li
            className={`flex items-center h-10 px-8 cursor-pointer  ${activeIndex == 2 ? 'bg-teal-100' : ''}`}
            onClick={() => { setActiveIndex(2); setSelectedPLName(""); }}
          >
            <img
              src={`${window.location.origin}/images/icons/music-icon.svg`}
              className="w-5 h-5"
              alt=""
            />
            <span className="ml-3">Categories</span>
          </li>
          <li
            className={`flex items-center h-10 px-8 cursor-pointer  ${activeIndex == 3 ? 'bg-teal-100' : ''}`}
            onClick={() => { setActiveIndex(3); navigate("/marketplace") }}
          >
            <img
              src={`${window.location.origin}/images/icons/purchase.png`}
              className="w-5 h-5"
              alt=""
            />
            <span className="ml-3">Marketplace</span>
          </li>
        </ul>
        
        {
          currentUsr && (currentUsr as any)?._id && (currentUsr as any)?._id !== "" && 
          <p className="px-8 py-5 mt-5">
            My Playists <ButtonPrimary className="px-2 py-1 sm:px-2 sm:py-1 " onClick={() => setShowModal(true)}  >+</ButtonPrimary>
          </p>
        }
        {
          playlistIdsOfUser.length > 0 &&
          <ul>
            {playlistIdsOfUser.map((item, index) =>
              <li className={`flex items-center justify-between h-10 px-8 cursor-pointer ${selectedPLName == (item as any)?.name ? 'bg-teal-100' : ''}`} key={index} value={(item as any)?.name || ""}  >
                <div className="flex" onClick={() => { setSelectedPLName((item as any)?.name || ""); setSelectedPLId((item as any)?._id || ""); setActiveIndex(100); }} >
                  <img
                    src={`${window.location.origin}/images/icons/folder-music-icon.svg`}
                    className="w-5 h-5 cursor-pointer"
                    alt=""
                  />
                  <span className="ml-3 cursor-pointer" >{(item as any)?.name || ""}</span>
                </div>
                <div className="flex items-center">
                  <Checkbox size="small" checked={((item as any)?.enablePlay == undefined || (item as any)?.enablePlay == true) ? true : false} onChange={() => onDisablePL((item as any)?._id)} />
                  <IconButton aria-label="delete">
                    <DeleteIcon fontSize="small" onClick={() => onDeleteAPL((item as any)?._id || '')} />
                  </IconButton>
                </div>
              </li>
            )}
          </ul>
        }

        <div className="mt-10 ">
          <p className="px-8 py-5">Advertise with us</p>
          <div className="flex items-center justify-center">
            <div>
              <video  className="w-40 h-40 my-4 border" controls>
                <source src={`${window.location.origin}/Pexels/pexels-anthony-shkraba-production.mp4`} type="video/mp4" />
                Your browser does not support HTML video.
              </video>
              <div className="w-40 h-40 my-4 border">
                  <img className="w-full " src={`${window.location.origin}/RF.png`} alt="" />
              </div>
              <div className="w-40 h-40 my-4 border">
                  <img className="w-full " src={`${window.location.origin}/purple_RF.png`} alt="" />
              </div>
              <div className="w-40 h-40 my-4 border">
                  <img className="w-full " src={`${window.location.origin}/RF.png`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`relative h-screen w-full lg:w-[calc(100%-640px)] transition-all bg-gray-50`}
      >
        {activeIndex === 0 && (
          <>
            <div className="flex justify-center m-5 ">
              <div className="relative flex-shrink-0 p-3 ">

                {
                  isVideo(allPlayableItems[currentPlayingMusicIndex] || undefined) === true &&
                  <VideoForNft 
                    src={(allPlayableItems[currentPlayingMusicIndex] as any)?.musicURL ? `${BACKEND_URL}/uploads/${(allPlayableItems[currentPlayingMusicIndex] as any)?.musicURL}` : undefined} 
                    nftId={(allPlayableItems[currentPlayingMusicIndex] as any)?._id || DEMO_NFT_ID}
                    muted={muteMusic}
                    />
                }
                <div
                  className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform">                    
                  <video  className="w-full h-full border" controls autoPlay={true} >
                <source src={`${window.location.origin}/Pexels/pexels-cottonbr.mp4`} type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                  {/* <Slider {...settings} className="w-full">
                    {
                      musicPlayerBannerImages.map((item, index) =>
                        <img
                          key={index}
                          src={item}
                          className="w-full rounded min-h-350 "
                          alt="S"
                        />
                      )
                    }
                  </Slider> */}
                </div>
              </div>
            </div>
            <div className="mx-5 my-3">
              {/* <div className="w-full">
                <p className="my-5 font-medium text-gray-400">Top Artists</p>
                <Slider {...settings} className="w-full">
                  {popularSellers &&
                    popularSellers.length > 0 &&
                    popularSellers.map((item, index) => (
                      <img
                        src={`${BACKEND_URL}/uploads/${(item as any)?.avatar || ""} `}
                        alt={`${(item as any)?.username || "s"}`}
                        key={index}
                        className="p-2 !w-fit max-h-36 cursor-pointer "
                        onClick={() => {
                          navigate(`/page-author/${(item as any)?._id}`);
                        }}
                      />
                    ))}
                </Slider>
              </div> */}

              <div className="mt-10 overflow-y-auto text-gray-400 ">
                <p className="my-5 font-medium">Top Songs</p>
                <div className="mb-24">
                  {popularItems &&
                    popularItems.length > 0 &&
                    popularItems.map((item, index) => (
                      <div
                        key={index}
                      >
                      <MusicListItem item={item} muteMusic={muteMusic} /> 
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
        {
          activeIndex === 1 && (
            <>
              <div className="mx-5 my-5 border rounded drop-shadow-lg">
                <div className="flex justify-between w-full px-4 py-4 bg-white">
                  <div className="flex justify-start w-full">
                    <img src={`${window.location.origin}/images/icons/search-line-icon.svg`} className="mx-3" width={25} alt="SH" />
                    <input type="text" className="w-full indent-2 outline-0" placeholder="For Title, Description, Artist, Creator, Collection ..."
                      value={keywordForSearch} onChange={(e) => setKeywordForSearch(e.target.value)}
                    />
                  </div>
                  <button className="px-10 py-3 ml-3 bg-gray-200"
                    onClick={() => onClickSearch()}
                  >
                    SEARCH
                  </button>
                </div>
              </div>
              <div className="mx-5 my-3">
                <div className="mb-24 overflow-y-auto text-gray-400  ">
                  {
                    itemsFoundByKeyword && itemsFoundByKeyword.length > 0 && itemsFoundByKeyword.map((item, index) =>
                      <div
                        key={index}
                      >                        
                        <MusicListItem item={item} muteMusic={muteMusic} /> 
                      </div>
                    )
                  }
                </div>
              </div>
            </>
          )
        }
        {
          activeIndex === 2 && (
            <>
              <div className="mx-5 my-5 border rounded drop-shadow-lg">
                <SectionSliderCategories heading=""
                  subHeading={consiseringCategory >= 0 ? `${(CATEGORIES.find(item => (item as any).value == consiseringCategory) as any).text || "Browse by category"}` : "Browse by category"}
                  className="w-full px-4 py-4 bg-white" onSelect={setConsideringCategory} isForMusicPlayer={true} />
              </div>
              <div className="mx-5 my-3">
                <div className="mb-24 overflow-y-auto text-gray-400 ">
                  {
                    itemsFoundByCategories && itemsFoundByCategories.length > 0 && itemsFoundByCategories.map((item, index) =>
                      <div
                        key={index}
                      >
                        <MusicListItem item={item} muteMusic={muteMusic} /> 
                      </div>
                    )
                  }
                </div>
              </div>
            </>
          )
        }
        {
          activeIndex === 100 &&
          <>
            <div className="mx-5 my-5 border-b drop-shadow-lg">
              <p className="my-5 ml-10 font-medium">{selectedPLName} Playlist</p>
            </div>
            <div className="mx-5 my-3">
              <div className="mb-24 overflow-y-auto text-gray-400 ">
                {
                  itemsOfaPL && itemsOfaPL.length > 0 && itemsOfaPL.map((item, index) =>
                    <div
                      key={index}
                    >
                    <MusicListItem item={item} muteMusic={muteMusic} /> 
                    </div>
                  )
                }
              </div>
            </div>
          </>
        }
      </div>

      {/* right sidebar */}
      <div
        className={`top-0 lg:top-auto fixed lg:relative h-screen lg:h-auto overflow-y-auto z-10 right-0 bg-white w-80 flex-shrink-0 border-l border-gray-200 px-8 transition ${showRightSidebar
            ? `translate-x-0`
            : `translate-x-full lg:translate-x-0`
          }`}
      >
        <div>
          <p className="pt-5 pb-1">Top Artists and Listeners</p>
          {
          popularSellers &&
            popularSellers.length > 0 &&
            popularSellers.map((item, index) => (
              <div
                className="flex items-center justify-between flex-shrink-0 py-4 border-b"
                key={index}
              >
                <div className="flex items-center">
                  <div className="w-10 h-10 bg-indigo-300 rounded">
                    <img
                      src={`${BACKEND_URL}/uploads/${(item as any)?.avatar || (item as any)?.gallery[0] || ""
                        }`}
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                  <div className="ml-3">
                    <p
                      className="text-lg font-medium text-gray-700 cursor-pointer"
                      onClick={() => navigate(`/page-author/${(item as any)._id || ""}`)}
                    >
                      {(item as any)?.username || ""}
                    </p>
                    <div className="flex">
                      <div className="flex items-center justify-start text-xs text-gray-500">
                        <img
                          src={`${window.location.origin}/images/icons/topsong.png`}
                          className="w-3 h-3"
                          alt="U"
                        />
                        <div className="ml-1">{(item as any)?.itemsCount || 0}</div>
                      </div>
                      <div className="flex items-center justify-start ml-3 text-xs text-gray-500">
                        <img
                          src={`${window.location.origin}/images/icons/user.png`}
                          className="w-3 h-3"
                          alt="U"
                        />
                        <div className="ml-1">{((item as any)?.follows as any)?.length || 0}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="px-2 py-1 text-sm bg-indigo-200 rounded"
                  onClick={() => {
                    toggleFollow(currentUsr?._id || "", (item as any)?._id || "");
                  }}
                >
                  {isFollowing && (isFollowing as any)[(item as any)?._id || ""] === true
                    ? "Following"
                    : "Follow"}
                </button>
              </div>
            ))}
        </div>
        <div className="mt-5 ">
          <p className="pt-5 mb-4">Advertise with us</p>
          <div className="flex items-center justify-center">
            <div>
              <video  className="w-40 h-40 my-4 border" controls>
                <source src={`${window.location.origin}/Pexels/pexels-pavel.mp4`} type="video/mp4" />
                Your browser does not support HTML video.
              </video>
              <div className="w-40 h-40 my-4 border">
                  <img className="w-full " src={`${window.location.origin}/RF.png`} alt="" />
              </div>
              <div className="w-40 h-40 my-4 border">
                  <img className="w-full " src={`${window.location.origin}/purple_RF.png`} alt="" />
              </div>
              <div className="w-40 h-40 my-4 border">
                  <img className="w-full " src={`${window.location.origin}/RF.png`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* responsive right slider button */}
      <div className="fixed right-0 z-20 -translate-y-full lg:hidden top-1/2">
        <button
          className={`rounded-rull w-5 transition ${showRightSidebar ? `rotate-180` : `rotate-0`
            }`}
          onClick={handleToggleRightSidebar}
        >
          <img src={`${window.location.origin}/images/icons/left-angle-arrow-outline-icon.svg`} alt="" />
        </button>
      </div>

      
      <div className="fixed bottom-0 left-0 right-0 z-20 flex flex-col items-center w-full px-10 py-5 bg-white md:flex-row drop-shadow-lg">
        <div className="flex items-center justify-center flex-column w-1/2 sm:w-3/12  ">
          <div className="flex flex-row text-sm font-medium text-center text-gray-700 md:ml-10 sm:my-5 ">
            <div>{allPlayableItems.length > 0 ? currentPlayingMusicIndex + 1 : 0}</div>
            <div>/</div> 
            <div>{allPlayableItems.length}</div>            
            {
              currentMediaRunning.state === "playing"?
                isVideo(currentMediaRunning.item) === true ?
                <video className="w-0 h-0" id="tone" preload="metadata"      >
                  <source src={`${BACKEND_URL}/uploads/${(currentMediaRunning.item as any)?.musicURL || ""}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                :
                <audio className="w-0 h-0"  id="tone" preload="metadata"  >
                  <source src={`${BACKEND_URL}/uploads/${(currentMediaRunning.item as any)?.musicURL || ""}`} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              :
                isVideo(allPlayableItems[currentPlayingMusicIndex]) === true ?
                <video className="w-0 h-0" id="tone" preload="metadata"      >
                  <source src={`${BACKEND_URL}/uploads/${(allPlayableItems[currentPlayingMusicIndex] as any)?.musicURL || ""}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                :
                <audio className="w-0 h-0"  id="tone" preload="metadata"  >
                  <source src={`${BACKEND_URL}/uploads/${(allPlayableItems[currentPlayingMusicIndex] as any)?.musicURL || ""}`} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
            }
          </div>
          <div className="flex items-center justify-around w-full">

            <button className="w-8 h-8 p-3 rounded-full drop-shadow-md bg-gradient-to-r from-green-400 to-blue-500"
              onClick={() => { currentPlayingMusicIndex > 0 && setCurrentPlayingMusicIndex(currentPlayingMusicIndex - 1); updateFileLength(); }}
            >
              <img
                src={`${window.location.origin}/images/icons/backward-icon.svg`}
                alt=""
                className="filter invert"
              />
            </button>
            {
              currentMediaRunning.state === "playing"?
              <AudioForNft
                src={
                  (currentMediaRunning.item as any)?.musicURL
                    ? `${BACKEND_URL}/uploads/${(currentMediaRunning.item as any)?.musicURL}`
                    : undefined
                }
                nftId={(currentMediaRunning.item as any)?._id || DEMO_NFT_ID}
                muted={muteMusic}
              />
              :
              <AudioForNft
                src={
                  (allPlayableItems[currentPlayingMusicIndex] as any)?.musicURL
                    ? `${BACKEND_URL}/uploads/${(allPlayableItems[currentPlayingMusicIndex] as any).musicURL}`
                    : undefined
                }
                nftId={(allPlayableItems[currentPlayingMusicIndex] as any)?._id || DEMO_NFT_ID}
                muted={muteMusic}
              />
            }
            <ButtonPlayMusicRunningContainer
              className="relative bottom-0 z-10"
              nftId={currentMediaRunning.state === "playing"? (currentMediaRunning.item as any)?._id || DEMO_NFT_ID : (allPlayableItems[currentPlayingMusicIndex] as any)?._id || DEMO_NFT_ID}
              renderDefaultBtn={() => renderListenButtonDefault(undefined, true)}
              renderPlayingBtn={() => renderListenButtonDefault("playing", true)}
              renderLoadingBtn={() => renderListenButtonDefault("loading", true)}
              increaseFunc={plusPlayCount}
            />
            <button className="w-8 h-8 p-3 rounded-full drop-shadow-md bg-gradient-to-r from-green-400 to-blue-500"
              onClick={() => { currentPlayingMusicIndex < allPlayableItems.length - 1 && setCurrentPlayingMusicIndex(currentPlayingMusicIndex + 1); updateFileLength(); }}
            >
              <img
                src={`${window.location.origin}/images/icons/forward-icon.svg`}
                alt=""
                className="filter invert"
              />
            </button>
            {/* <button className="rounded-full drop-shadow-md w-8 h-8 p-2.5 bg-gradient-to-r from-green-400 to-blue-500">
              <img
                src="/images/icons/arrow-back-svgrepo-com.svg"
                alt=""
                className="filter invert"
              />
            </button> */}
          </div>
        </div>
    
        <div className="flex flex-col items-center justify-center w-full sm:flex-row">
          <div className="flex items-center justify-center w-1/3">
            {
              
              currentMediaRunning.state === "playing"?
              <>
                <img
                    src={`${BACKEND_URL}/uploads/${(currentMediaRunning.item as any)?.logoURL || ""}`}
                    className=""
                    width={50}
                    height={50}
                    alt=""
                  />
                <div className="pl-3">
                  <div className="text-sm font-medium text-gray-700">
                    {(currentMediaRunning.item as any)?.name || ""}
                  </div>
                  <div className="text-xs text-gray-500">
                    {(currentMediaRunning.item as any)?.description || ""}
                  </div>
                </div>
              </>
              :
              allPlayableItems.length>0 && (allPlayableItems[currentPlayingMusicIndex] as any)?.logoURL !== "" ? 
              <>
                <img
                  src={`${BACKEND_URL}/uploads/${(allPlayableItems[currentPlayingMusicIndex] as any)?.logoURL || ""}`}
                  className=""
                  width={50}
                  height={50}
                  alt=""
                />
                <div className="pl-3">
                  <div className="text-sm font-medium text-gray-700">
                    {(allPlayableItems[currentPlayingMusicIndex] as any)?.name || ""}
                  </div>
                  <div className="text-xs text-gray-500">
                    {(allPlayableItems[currentPlayingMusicIndex] as any)?.description || ""}
                  </div>
                </div>
              </>
              :
              <div className="pl-3">
                <div className="text-sm font-medium text-gray-700">
                  No music
                </div>
              </div>
            }
          </div>
          <div className="w-full sm:w-2/3">
            <div className="flex justify-between">
              <div className="text-xs text-gray-400">00:00</div>
              <div className="text-xs text-gray-400">{fileLength}</div>
            </div>
            <div className="w-full h-3 overflow-hidden bg-gray-200 rounded-full"  >
              <div className="h-full bg-gradient-to-r from-green-400 to-blue-500"  style={{ width: widthOfProgressBar}} ></div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center w-2/3 px-0 sm:w-1/2 md:w-3/12 sm:px-10">
          <button className="w-5 h-5 mx-2" onClick={() => onMuteMusic()}>
            <img src={muteMusic === false? `${window.location.origin}/images/icons/volume-icon.svg` : `${window.location.origin}/images/icons/volume-mute-icon.svg`} alt="" />
          </button>
          <div className="w-full h-3 overflow-hidden bg-gray-200 rounded-full">
            <div className="h-full bg-yellow-500" style={{ width: muteMusic === false? "100%" : "0" }}></div>
          </div>
        </div>
      </div>

      {/* add modal */}
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                  <h3 className="text-3xl font-semibold">Add New List</h3>
                </div>
                {/*body*/}
                <div className="relative flex-auto p-6">
                  <input
                    type="text"
                    className="h-10 border w-72 indent-3"
                    placeholder="Your New List Name"
                    value={nameOfNewPL}
                    onChange={(e) => setNameOfNewPL(e.target.value)}
                  />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                  <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                    type="button"
                    onClick={() => { setShowModal(false); onCreateEmptyList(); }}
                  >
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </div>
  );
};

export default Myprofile;
