import { Popover, Transition } from "@headlessui/react";
import { BACKEND_URL, chains, platformChainIds } from "config";
import { avatarImgs , defaultAvatar} from "contains/fakeData";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import isEmpty from "utilities/isEmpty";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { changeAuthor, changeChainId, changeGlobalProvider, changeWalletAddress, changeWalletStatus, selectCurrentChainId, selectCurrentUser, selectGlobalProvider } from "app/reducers/auth.reducers";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { changeNetwork } from "InteractWithSmartContract/interact";
import { toast } from "react-toastify";
import Web3 from "web3";
import { web3Modal } from "./MainNav2Logged";

export default function SelectNetworkDropdown() {
  
  const dispatch = useAppDispatch();
  const globalUser = useAppSelector(selectCurrentUser);
  const currentChainId = useAppSelector(selectCurrentChainId);
  const globalPrivider = useAppSelector(selectGlobalProvider);
  const navigate = useNavigate();
  
  const compressWalletAddr = (defultAddr: string) => {
    if(!isEmpty(defultAddr)) return defultAddr.substring(0, 6)+"..."+defultAddr.substring(38, 42);
    return ""
  }

  const switchNetwork = async (chainId: number) => {
    let changed = await changeNetwork(globalPrivider, chainId);
    if((changed as any).success === true) {      
      var provider = await web3Modal.connect();
      var web3 = new Web3(provider);
      var accounts = await web3.eth.getAccounts();

      dispatch(changeGlobalProvider(provider));
      if (accounts[0]) {
        dispatch(changeWalletAddress(accounts[0]));
        dispatch(changeWalletStatus(true));
      } else {
        dispatch(changeWalletAddress(""));
        dispatch(changeWalletStatus(false));      
      }
      dispatch(changeChainId(chainId));        
    }
    else {
      toast.error(`Please reconnect your wallet to ${(chains as any)[platformChainIds[0]].name || ""} or ${(chains as any)[platformChainIds[1]].name || ""} and try again.`);                              
      dispatch(changeWalletAddress(""));
      dispatch(changeWalletStatus(false));      
    }
  }

  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <ButtonPrimary sizeClass="px-4 py-2 sm:px-5">
              {
                currentChainId === 0?
                "Select a network"
                :
                currentChainId === platformChainIds[0] || currentChainId === platformChainIds[1]?
                (chains as any)[currentChainId]?.name
                :
                "Unusable network"
              }  
              </ButtonPrimary>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-3 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden shadow-lg rounded-3xl ring-1 ring-black ring-opacity-5">
                  <div className="relative grid grid-cols-1 gap-6 px-6 bg-white dark:bg-neutral-800 py-7">
                  {
                    platformChainIds.map((item, index) => (
                      <div className="flex items-center space-x-3" key={index} 
                        onClick={() => switchNetwork(item)}
                      >
                      {
                        (chains as any)[item].name
                      }
                      </div>
                    ))
                  }
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
