import React, { FC, useState, useEffect } from "react";
import facebookSvg from "images/socials/facebook.svg";
import twitterSvg from "images/socials/twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL, chains, platformChainIds } from "config";
import { toast } from 'react-toastify';
import { changeAuthor, changeDetailedUserInfo, changeGlobalProvider, changeWalletStatus, selectGlobalProvider } from "app/reducers/auth.reducers";
import { changeNetwork, signString } from "InteractWithSmartContract/interact";
import Web3 from "web3";
import Web3Modal from "web3modal";
import {
  changeChainId,
  changeWalletAddress,
  selectCurrentWallet,
  selectCurrentChainId
} from 'app/reducers/auth.reducers';
import { providerOptions } from "InteractWithSmartContract/providerOptions";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { web3Modal } from "components/Header/MainNav2Logged";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  
  const [username, setUsername] = useState("");
  const [walletAccount, setWalletAccount] = useState("");
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const globalProvider = useAppSelector(selectGlobalProvider);
  const globalAddress = useAppSelector(selectCurrentWallet);
  const globalChainId = useAppSelector(selectCurrentChainId);
  const [connected, setConnected] = useState(false);
  const [provider, setProvider] = useState({});
  const [compressedAccount, setCompressedAccount] = useState("");

  useEffect(() => {
    if (globalAddress && globalChainId) {
      setConnected(true);
      dispatch(changeWalletStatus(true));
    } else {
      setConnected(false);
      dispatch(changeWalletStatus(false));
    }
  }, [globalAddress, globalChainId]);

  useEffect(() => {
    if (globalAddress) {
      let accountStr = globalAddress.toString();
      setCompressedAccount(
        accountStr.substring(0, 5) +
          "..." +
          accountStr.substring(accountStr.length - 4, accountStr.length)
      );
    } else {
      setCompressedAccount("");
    }
  }, [globalAddress]);

  const onClickDisconnect = async () => {
    try {
      await web3Modal.clearCachedProvider();
    } catch (e) {}
    setConnected(false);
    dispatch(changeChainId(0));
    dispatch(changeWalletAddress(""));
    dispatch(changeGlobalProvider({}));
    dispatch(changeWalletStatus(false));
  };

  useEffect(() => {
    if ((provider as any ).on) {
      const handleAccountsChanged = (accounts:Array<any>) => {
        if (accounts[0]) {
          dispatch(changeWalletAddress(accounts[0]));
          setConnected(true);
          dispatch(changeWalletStatus(true));
        } else {
          dispatch(changeWalletAddress(""));
          setConnected(false);
          dispatch(changeWalletStatus(false));
        }
      };

      const handleChainChanged = (chainId: number) => {
        dispatch(changeChainId(chainId));
      };

      const handleDisconnect = () => {
        onClickDisconnect();
      };

      (provider as any).on("accountsChanged", handleAccountsChanged);
      (provider as any).on("chainChanged", handleChainChanged);
      (provider as any).on("disconnect", handleDisconnect);

      return () => {
        if ((provider as any).removeListener) {
          (provider as any).removeListener("accountsChanged", handleAccountsChanged);
          (provider as any).removeListener("chainChanged", handleChainChanged);
          (provider as any).removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider, dispatch]);
  
  function ValidateWalletAccount() 
  {
   if (/^0x[a-fA-F0-9]{40}$/.test(walletAccount))
    {
      return (true)
    }
      return (false)
  }

  const doLogin = (walletStr: string, signedStr:string) => {
    axios({
      method: "post",
      url: `${BACKEND_URL}/api/users/login`,
      data: {
        address: walletStr,
        password: signedStr
      }
    })
      .then(function (response) {
        if (response.data.code === 0) {
          //set the token to sessionStroage   
          const token = response.data.token;
          localStorage.setItem("jwtToken", response.data.token);
          let decoded = {id: "", _doc:{}};
          decoded = jwt_decode(token);
          dispatch(changeAuthor(decoded._doc));
          if(decoded.id) 
          {
            axios.post(`${BACKEND_URL}/api/users/findOne`, { userId: decoded.id }, {
              headers:
              {
                  "x-access-token": localStorage.getItem("jwtToken")
              }
            }).then((result) => {
              dispatch(changeDetailedUserInfo(result.data.data));                    
              navigate("/account");
            }).catch(() => {
                console.log("Get detailed userInfo failed.");
            });
          }
        }
      })
      .catch(function (error) {
        toast.error(error);
      });
  }
  
  const onClickSignUp = async () => {
    if(username  === "" || !username) 
    {
      toast.error("You have entered an invalid username!");
      return;
    }
    if(ValidateWalletAccount() === false)
    {
      toast.error("You have entered an invalid wallet account!");
      return;      
    }    
    try 
    {    
      var provider = await web3Modal.connect();
      var web3 = new Web3(provider);
      var accounts = await web3.eth.getAccounts();
      var chainId = await web3.eth.getChainId();

      setProvider(provider);
      dispatch(changeGlobalProvider(provider));
      if (accounts[0]) {
        dispatch(changeWalletAddress(accounts[0]));
        setConnected(true);
        dispatch(changeWalletStatus(true));
      } else {
        dispatch(changeWalletAddress(""));
        setConnected(false);
        dispatch(changeWalletStatus(false));
      }
      dispatch(changeChainId(chainId));      
      if(chainId !== platformChainIds[0] && chainId !== platformChainIds[1])
      {
        let changed = await changeNetwork(provider, platformChainIds[0]);
        if((changed as any).success === true) {         
          provider = await web3Modal.connect(); 
          setProvider(provider);
          dispatch(changeGlobalProvider(provider));
          dispatch(changeChainId(platformChainIds[0]));
        }
        else {
          toast.error(`Please reconnect your wallet to ${(chains as any)[platformChainIds[0]].name || ""} or ${(chains as any)[platformChainIds[1]].name || ""} and try again.`);          
          dispatch(changeWalletAddress(""));
          setConnected(false);
          dispatch(changeWalletStatus(false)); 
          return;
        }
      }
      if(walletAccount.toLowerCase().trim() !== accounts[0].toString().toLowerCase().trim() ) {

        toast.warn("Registering wallet address should be equal to connectied wallet address. Pleast input again and retry. " );
        return;
      }
      let signingResult = {success: false, message: ""};
      signingResult = await signString(web3, walletAccount);
      if (signingResult.success === true) 
      {
        await axios.post(
          `${BACKEND_URL}/api/users/create`,
          {
            address: walletAccount,
            username: username,
            password: signingResult.message
          }
        ).then((response) => {
          if(response.data.code === 1) 
          {
            let errMsg = "Address is duplicated.";
            toast.warn(errMsg);
            return;
          }else if(response.status === 200){
            doLogin(walletAccount, signingResult.message);
            return;
          }
          toast.error("Sing up failed with network error.");
        }).catch((error) => {
          toast.warn("Sign to message error : " + error);
        })
      }else{
        toast.error("Please connect your wallet correctly and continue again.");
      }
    } catch (error) {
      toast.error("Wallet connection error : " + error);
      setConnected(false);
      dispatch(changeWalletStatus(false));
      dispatch(changeWalletAddress(""));
    }  
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Peeke NFT Marketplace</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
        
          <div className="grid grid-cols-1 gap-6" >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                User Name
              </span>
              <Input
                type="username"
                placeholder="username"
                className="mt-1 border"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </label>
            
            <label className="block">
                <span className="flex items-center justify-between text-neutral-800 dark:text-neutral-200">
                Wallet Address
                </span>
                <div className="mt-1.5 relative text-neutral-700 dark:text-neutral-300">
                  <Input
                    className="!pr-10 "                    
                    placeholder="0x1bde388826caab77bfe80148abdce6830606e2c6"
                    value={walletAccount}
                    onChange={(e) => setWalletAccount(e.target.value)}
                  />                  
                </div>
            </label>
            <ButtonPrimary onClick={() => {onClickSignUp()}}>Connect Wallet</ButtonPrimary>
          </div>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login" className="text-green-600">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
