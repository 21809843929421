import Heading from "components/Heading/Heading";
import { nftsAbstracts } from "contains/fakeData";
import React, { FC, useEffect, useState } from "react";
import CardNFTMusic from "./CardNFTMusic";
import CardNFTMusic2 from "./CardNFTMusic2";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { changeCollectionList, selectConllectionList } from "app/reducers/collection.reducers";
import { BACKEND_URL } from "config";
import axios from "axios";
import { changeItemsList, selectItemList } from "app/reducers/nft.reducers";
import Slider from "rc-slider";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface SectionMagazine8Props {
  className?: string;
}

const SectionMagazine8: FC<SectionMagazine8Props> = ({ className = "" }) => {

  const items = useAppSelector(selectItemList);
  const dispatch = useAppDispatch();
  const [musicItems, setMusicItems] = useState(Array<any>);
  const [pageIndex, setPageIndex] = useState(0);
  const NumberOfItemsPerPage = 16;

  const fetchAllItems = async () => {
    await axios.post(`${BACKEND_URL}/api/item/`, { }, {
      headers:
      {
          "x-access-token": localStorage.getItem("jwtToken")
      }
    }).then((result) => {
      console.log("fetchAllItems : ", result.data.data)
      dispatch(changeItemsList(result.data.data));
    }).catch(() => {

    });
  }

  useEffect(() => {
    if(items && items?.length > 0)
    {
      let mItems = [];
      mItems = items.filter( x => x?.musicURL?.toLowerCase().includes("mp3") );
      setMusicItems(mItems);
    }
  }, [items]);

  useEffect( () => {    
    fetchAllItems();
  }, [])

  return (
    <div className={`nc-SectionMagazine8 relative ${className}`}>
      <Heading
        desc={"Click on the thumbnails below and enjoy NTF music or audio content"}
        className="mb-14 text-neutral-900 dark:text-neutral-50"
      >
        Listen to our diverse audio NFTs
      </Heading>
      <div className={`grid grid-cols-1 sm:grid-cols-4 gap-4 2xl:gap-6`}>
        {
          musicItems && musicItems.length>0 && musicItems?.map((x, index) =>  (
            index < NumberOfItemsPerPage * (pageIndex+1) && 
            <CardNFTMusic
            className="mt-1 ml-1 mr-1"
              item={x}
              key={index}
              hideHeart={false} 
            />
          ))
        }
      </div>
      {
        musicItems && musicItems.length>0 && musicItems.length - NumberOfItemsPerPage * (pageIndex+1) > 0 &&
        <ButtonSecondary onClick={() => { setPageIndex(pageIndex + 1)} }>Show me more </ButtonSecondary>
      }
    </div>
  );
};

export default SectionMagazine8;
