import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Tip.module.sass";
import isEmpty from "utilities/isEmpty";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { BACKEND_URL, chains, minimumTipOnBinance, minimumTipOnEthereum } from "config";
import { useAppSelector } from "app/hooks";
import { selectCurrentChainId, selectCurrentUser } from "app/reducers/auth.reducers";
import axios from "axios";
import MySwitch from "components/MySwitch";
import FormItem from "components/FormItem";
import { newBanner } from "contains/fakeData";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const Tip = ({ className = "" ,onOk, onCancel, nft = {} }) => {

  const [price, setPrice]  = useState(0);
  const [priceIsInvalid, setPriceIsInvalid] = useState(false);  
  const [warningStr, setWarningStr] = useState(""); 
  const [playlistIsInvalid, setPlaylistIsInvalid] = useState(false);  
  const regularInputTestRegExp = /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/gm;
  const currentUsr = useAppSelector(selectCurrentUser);
  const [playlistIdsOfUser, setPlaylistIdsOfuser] = useState([]);
  const [nameOfNewPL, setNameOfNewPL] = useState("");
  const [createNew, setCreateNew] = useState(false);
  const [nameOfSelectedPL, setNameOfSelectedPL] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    if(location.pathname.includes("nft-detail") === true)
    {
      if(!currentUsr || currentUsr?._id === undefined || currentUsr?._id === "" )
      {
        toast.warn("You must be logged in to do playlist operation. Please log in");
        navigate("/");
      }
    }
  }, [])

  const getPlaylistOfUser = async () => {
    if(currentUsr?._id && currentUsr._id !== "")
    {
      await axios.post(
        `${BACKEND_URL}/api/playlist/getPlayListsOfUser`,
        {
          userId: currentUsr?._id || ""
        }
      )
      .then((response) => {
        if(response.data.data.length >0) setCreateNew(false);
        else setCreateNew(true);
        setPlaylistIdsOfuser(response.data.data || []);
      })
    }else{
      setPlaylistIdsOfuser([]);
      setCreateNew(true);
    }
  }

  const onChangePrice = (e) =>
  {
    var inputedPrice = e.target.value;    
    if(inputedPrice !== "") 
    {
      setPriceIsInvalid(false);
      let m; let correct = false;
      while ((m = regularInputTestRegExp.exec(inputedPrice)) !== null) 
      {
        if (m.index === regularInputTestRegExp.lastIndex) {
          regularInputTestRegExp.lastIndex++;
        }
        if(m[0] === inputedPrice) 
        {
          correct = true;
        }         
      }      
      if(!correct)         
      {
        setPriceIsInvalid(true);
        setWarningStr("Price must be a number.");
        return;
      }
    }        
    if(isNaN(inputedPrice))
    {
      setPriceIsInvalid(true);
      setWarningStr("Bidding price must be a valid number.");
      return;
    }
    setPriceIsInvalid(false); 
    setWarningStr("");   
    setPrice(inputedPrice);
  } 

  const onContinue = () => {
    if((createNew === false && nameOfSelectedPL === "") || (createNew === true && nameOfNewPL  === "") ) 
    {
      setPlaylistIsInvalid(true);
      return;
    }
    if(currentUsr?._id !== nft?.creator?._id && currentUsr?._id !== nft?.owner?._id)
    {
      if(isNaN(price))
      {
        setPriceIsInvalid(true);
        setWarningStr("Bidding price must be a valid number.");
        return;
      }
      if( Number(price) <= Number(chains[nft?.chainId || 1]?.minimumTipAmount || 0))
      {
        setWarningStr(`Tip amount should be bigger than ${chains[nft?.chainId || 1]?.minimumTipAmount || 0} `);
        setPriceIsInvalid(true);
        return;
      }
      setWarningStr("");
      setPriceIsInvalid(false);
    }
    setTimeout(() => 
    {
      onOk(Number(price), createNew === true? nameOfNewPL : nameOfSelectedPL);
    }, 1000)
  }

  useEffect(() => {
    getPlaylistOfUser();
  }, [])


  return (
    <div className={cn(className, styles.checkout)}>
      <div className={styles.stage}>Add item to playlist</div>
      <div className={styles.info}>
        You are about to pay to artist of <strong>{nft && nft.name}</strong>, once after you give tip, then this item will be added to your playlist.
      </div>
      
      <MySwitch
        label={"Use a previous list or create a new one."}    
        desc=""
        enabled={createNew}
        onChange={setCreateNew}
      />        
      {
        playlistIdsOfUser && playlistIdsOfUser.length>0 && createNew == false &&
        <select className="w-full border rounded-xl"  placeholder="Select a playilst name"
        defaultValue={""}
          value={nameOfSelectedPL}  onChange={(e) => setNameOfSelectedPL(e.target.value)} 
        >
          <option value={""} ></option>
          {
            playlistIdsOfUser.map((item, index) => 
            <option value={item.name} key={index} >{item.name}</option>
            )
          }
        </select>
      }
      {createNew == true &&
          <input
            className={styles.input }
            type="text"
            name="price"
            id="priceInput"
            value={nameOfNewPL || ""}
            onChange = {(e) => setNameOfNewPL(e.target.value)}
            placeholder={`Input the name of new playlist`}
          />
      }
      {
        playlistIsInvalid === true ? 
        <span style={{ color: "red" }}>"Please select a previous PL or input new name"</span>
        :
        <span style={{ color: "greeb" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      }
      {
        currentUsr?._id !== nft.creator._id && currentUsr?._id !== nft.owner._id && 
        <>
      <div className={styles.stage+" mt-6"}>Your tip </div>      
      <div className={styles.stage2}>{`${chains[nft.chainId || 1]?.minimumTipAmount || 1} TheRadios on ${chains[nft.chainId || 1]?.name || "Ethereum Mainnet"}.`}</div>      

      <div className={styles.table}>
        <div className={styles.field}>
          <input
            className={styles.input}
            type="text"
            name="price"
            id="priceInput"
            value={price || ""}
            onChange = {(e) => onChangePrice(e)}
            placeholder={`Must be bigger than minimum tip amount`}
          />
        </div>       
        {
          priceIsInvalid === true ? 
          <span style={{ color: "red" }}>{warningStr}</span>
          :
          <span style={{ color: "greeb" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        }
      </div>
      </>
    }
      <div className={styles.btns}>
        <ButtonPrimary onClick={onContinue}>Continue</ButtonPrimary>
        <ButtonPrimary onClick={onCancel}>Cancel</ButtonPrimary>
      </div>
    </div>
  );
};

export default Tip;
