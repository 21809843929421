import Logo from "shared/Logo/Logo";
import { NavLink, useNavigate } from "react-router-dom";
import { CustomLink } from "data/types";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Getting started",
    menus: [
      { href: "#", label: "Installation" },
      { href: "#", label: "Release Notes" },
      { href: "#", label: "Upgrade Guide" },
      { href: "#", label: "Browser Support" },
      { href: "#", label: "Editor Support" },
      { href: "#", label: "Dark Mode" },
    ],
  },
  {
    id: "1",
    title: "Explore",
    menus: [
      { href: "#", label: "Design features" },
      { href: "#", label: "Prototyping" },
      { href: "#", label: "Design systems" },
      { href: "#", label: "Pricing" },
      { href: "#", label: "Customers" },
      { href: "#", label: "Security" },
    ],
  },
  {
    id: "2",
    title: "Resources",
    menus: [
      { href: "#", label: "Best practices" },
      { href: "#", label: "Support" },
      { href: "#", label: "Developers" },
      { href: "#", label: "Learn design" },
      { href: "#", label: "What's new" },
      { href: "#", label: "Releases" },
    ],
  },
  {
    id: "4",
    title: "Community",
    menus: [
      { href: "#", label: "Discussion Forums" },
      { href: "#", label: "Code of Conduct" },
      { href: "#", label: "Community Resources" },
      { href: "#", label: "Contributing" },
      { href: "#", label: "Concurrent Mode" },
      { href: "#", label: "API Reference" },
    ],
  },
];

const Footer: React.FC = (props:any) => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const location  = useLocation();
  const [hide, setHide] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if((location as any).pathname === "/musicplayer") setHide(true);
    else setHide(false);
  }, [location])

  return (
    <>
    {
      hide === false?
      <div className="relative py-10 border-t nc-Footer lg:pt-25 lg:pb-40 border-neutral-200 dark:border-neutral-700 ">
          <div className="px-20 " style={{ display:"flex", alignItems:"center", "justifyContent":"space-around" }}>
            <div className="col-span-2 md:col-span-1">
              <Logo />
            </div>
          <h2 style={{cursor: "pointer"}} onClick={() => { navigate("/termsconditions"); }}>Terms & Conditions</h2>
          <h2>Contact us: <a target="_blank" href="https://info@peeke.ch">info@peeke.ch</a></h2>
          </div>
      </div>
      :
      <></>
    }
    </>
  );
};

export default Footer;
