import React, { FC, useEffect, useId, useRef, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardNFTImage from "./CardNFTImage";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectItemList } from "app/reducers/nft.reducers";

export interface SectionSliderCardNftImageProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
}

const SectionSliderCardNftImage: FC<SectionSliderCardNftImageProps> = ({
  className = "",
  itemClassName = "",
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  const items = useAppSelector(selectItemList);
  const [videoItems, setVideoItems] = useState(Array<any>);

  useEffect(() => {
    if(items && items?.length > 0)
    {
      let vItems = [];
      vItems = items.filter( x => x?.musicURL?.toLowerCase().includes("png") );
      setVideoItems(vItems);
    }
  }, [items]);

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const OPTIONS: Glide.Options = {
      perView: 3,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 2.5,
        },
        1024: {
          gap: 20,
          perView: 2.3,
        },
        768: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS, videoItems]);

  return (
    <div className={`nc-SectionSliderCardNftImage ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading desc="Click on play icon and enjoy NTFs image" hasNextPrev>
          Explore NFTs Image
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {
            videoItems && videoItems.length>0 && videoItems.map((x, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <CardNFTImage  item={x} key={index} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderCardNftImage;
