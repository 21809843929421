import {useEffect} from "react";
import MyRouter from "routers/index";
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch } from "app/hooks";
import { changeAuthor, changeDetailedUserInfo } from "app/reducers/auth.reducers";
import axios from "axios";
import { BACKEND_URL } from "config";
import { socketUrl } from "config";
import { io } from 'socket.io-client';
import { changeSystemTime } from "app/reducers/bid.reducers";
import { changeETHPrice } from "app/reducers/nft.reducers";
import { networkInterfaces } from "os";

var socket = io(`${socketUrl}`);

function App() {  
  const dispatch = useAppDispatch();
  
  socket.on("ServerTime", (data:any) => {
    // dispatch(changeSystemTime(data));      
   })

  const fetchETHPrice = async () => {
    const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd');
    const data = await response.json();
    dispatch(changeETHPrice(Number(data["matic-network"].usd)));
  }

  useEffect(() => {    
    async function checkValidLogin() {

      if (localStorage.jwtToken !== undefined &&
        localStorage.jwtToken !== "" &&
        localStorage.jwtToken !== null) {
        let decoded  = {
          app: 0, _doc: { _id:""}, 
        };
        decoded = jwt_decode(localStorage.jwtToken);
        const currTime = Date.now() / 1000;
          if (decoded.app < currTime) {
            dispatch(changeAuthor({}));
            localStorage.removeItem("jwtToken");
            toast.warn("Session timeouted. Please sign in again");
          }
          else {    
            if(decoded && decoded?._doc) dispatch(changeAuthor(decoded._doc || {}));
            axios.post(`${BACKEND_URL}/api/users/findOne`, { userId: decoded._doc._id }, {
              headers:
              {
                  "x-access-token": localStorage.getItem("jwtToken")
              }
          }).then((result) => {
              dispatch(changeDetailedUserInfo(result.data.data || {}));
          }).catch(() => {
              console.log("Get latest userInfo failed.");
          });
          }
      }
    }
    
    let interval = setInterval( () => {
      fetchETHPrice();
    }, 30000);
    return () => {clearInterval(interval)}
    
  }, [])
  

  return (
    <div className="text-base bg-white dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
