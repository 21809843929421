const IS_TEST = true;
export const INFURA_KEY="1b4c44fdf5a0404b91ee1a85db0aed9a";
export const BACKEND_URL = 
 IS_TEST === false?
 "https://radioreum.com"
 :
 //"http://localhost:5010";
 "https://value.peeke.ch";

export const CATEGORIES = [
    { value: 1, text: "Art" },
    { value: 2, text: "Interiors & Decorations" },
    { value: 3, text: "Jewellery & Precious Stones" },
    { value: 4, text: "Watches" },
    { value: 5, text: "Houses & Flats" }
  ];

export const admin_address = "0xB0cD0F92B4471faACAe06136EBD4Ed0D872D8bBE";
export const baseUrl =  BACKEND_URL+"/api/";
export const socketUrl = BACKEND_URL+"/socket/";
export const imgUrl =  BACKEND_URL+"/uploads/";
export const ipfsUrl = 'https://ipfs.infura.io/ipfs/';
export const platformContractAbi = require("./InteractWithSmartContract/RadioNFTFactory.json");
export const nftContractAbi = require("./InteractWithSmartContract/nftContract-abi.json");
export const erc20ContractAbi = require("./InteractWithSmartContract/TheRadio.json");

export const chains = 
{    
  137: {
    name: "Polygon Mainnet",
    id: 137,
    rpcURL: "https://polygon.llamarpc.com/",
    currency: "MATIC",
    platformContractAddress: "0xf1f63B7af9Ae1a1641fea63BAFF5e73520Ac1255",
    nftContractAddress: "0x0D60968767dD2c8C2cFea46A3FfF2f495e6FF735"
  },
  80001: {
    name: "Polygon test network",
    id: 80001,
    rpcURL: "https://polygon-mumbai-bor.publicnode.com",
    currency: "MATIC",
    platformContractAddress: "0xa1Aca58cD2d50435D1944Cc8cC65F8BE2e18531D",
    nftContractAddress: "0x213B9eE04e4Cf6D9E9cCDb698b9cf149F0aEAa34"
  }
}; 

export const platformChainIds = [137];
