import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import { Helmet } from "react-helmet";
import SectionMagazine8 from "components/SectionMagazine8";
import SectionMagazineVideo from "components/SectionMagazineVideo";
import SectionSliderCardNftDocument from "components/SectionSliderCardNftDocument";
import SectionSliderCardNftImage from "components/SectionSliderCardNftImage";
import SectionSliderCollections2 from "components/SectionSliderCollections2";
import { newBanner } from "contains/fakeData";
import Heading from "components/Heading/Heading";

function PageHome() {

  return (
    <div className="relative overflow-hidden nc-PageHome">
      <Helmet>
        <title>Home || Peeke NFT Marketplace </title>
      </Helmet>
   
      <div className="container relative mt-3 mb-20 sm:mb-24 lg:mt-6 lg:mb-32">
        <Heading
          className="lg:mb-14 text-neutral-900 dark:text-neutral-50"
          fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
        >
        Give real world NFT value
        </Heading>
        <img src={newBanner} className="mt-3 lg:mt-5 xl:mt-7" alt="banner" />
      </div>

      
      <div className="container relative my-24 space-y-24 lg:space-y-32 lg:my-32">
    
          <SectionSliderCategories />      

        <div className="relative py-5 lg:py-7">
          <BackgroundSection />
          <SectionGridAuthorBox
            sectionStyle="style2"
            data={Array.from("11111111")}
            boxCard="box4"
          />
        </div>

          <SectionSliderCollections2 cardStyle="style2" />

        <SectionSliderCardNftImage />
        <div className="relative py-5 lg:py-7">
          <BackgroundSection />
          <SectionMagazine8 />
        </div>
        <SectionSliderCardNftDocument />
        <div className="relative py-5 lg:py-7">
          <BackgroundSection />
          <SectionMagazineVideo />
        </div>
      </div>
      
    </div>
  );
}

export default PageHome;
