export const AUTH_SUCCESS="AUTH_SUCCESS";
export const AUTH_LOGOUT="AUTH_LOGOUT";
export const CURRENT_USER="GET_CURRENT_USER";
export const GET_USER_DETAIL="GET_USER_DETAIL";
export const SET_WALLET_ADDR = "SET_WALLET_ADDR";
export const SET_CHAIN_ID = "SET_CHAIN_ID";
export const SET_OTHER_USER_DETAIL = "SET_OTHER_USER_DETAIL";
export const UPDATE_USER_BALANCE = "UPDATE_USER_BALANCE";
export const UPDATE_WALLET_STATUS = "UPDATE_WALLET_STATUS";

export const UPDATE_NFT_BANNER_LIST = "UPDATE_NFT_BANNER_LIST";
export const UPDATE_HOT_BID_LIST = "UPDATE_HOT_BID_LIST";
export const UPDATE_HOT_COLLECTION_LIST = "UPDATE_HOT_COLLECTION_LIST";
export const UPDATE_COLLECTION_LIST = "UPDATE_COLLECTION_LIST";
export const UPDATE_COLLECTION_BANNER_LIST = "UPDATE_COLLECTION_BANNER_LIST";
export const GET_COLLECTION_DETAIL = "GET_COLLECTION_DETAIL";
export const BUY_COLLECTION_SUCCESS = "BUY_COLLECTION_SUCCESS";
export const SET_CONSIDERING_COLLECTION_ID = "SET_CONSIDERING_COLLECTION_ID";

export const UPDATE_ITEMS_OF_COLLECTION = "UPDATE_ITEMS_OF_COLLECTION";

export const GET_HOT_COLLECTION = "GET_HOT_COLLECTION";

export const BID_SUCCESS = "BID_SUCCESS";
export const BID_FAILED = "BID_FAILED";
export const UPDATE_SERVER_TIME = "UPDATE_SERVER_TIME";

export const SET_SERVICE_FEE = "SET_SERVICE_FEE";
export const GET_NFT_DETAIL = "GET_NFT_DETAIL";
export const BUY_NFT_SUCCESS = "BUY_NFT_SUCCESS";
export const UPDATE_POPULAR_USERS = "UPDATE_POPULAR_USERS";
export const SET_NFT_TRADING_RESULT = "SET_NFT_TRADING_RESULT";

export const UPDATE_ITEMS_OF_USER_BY_CONDITION = "UPDATE_ITEMS_OF_USER_BY_CONDITION";

export const FOLLOW_SUCCESS = "FOLLOW_SUCCESS";
export const FOLLOW_FAILED = "FOLLOW_FAILED";
export const UPDATE_FOLLOW_LIST = "UPDATE_FOLLOW_LIST";
export const UPDATE_FOLLOWING_LIST = "UPDATE_FOLLOWING_LIST";
export const IS_FOLLOWING_EXISTS = "IS_FOLLOWING_EXISTS";

export const UPLOAD_CREATE="UPLOAD_CREATE";
export const UPLOAD_UPDATE="UPLOAD_UPDATE";
export const UPLOAD_DELETE="UPLOAD_DELETE";
export const UPLOAD_CREATE_FAILL="UPLOAD_CREATE_FAILL";
export const UPLOAD_CREATE_SUCCESS="UPLOAD_CREATE_SUCCESS";

export const GET_UPLOADED_NFTS_SUCCESS = "GET_UPLOADED_NFTS_SUCCESS";
export const GET_UPLOADED_NFTS_FAIL = "GET_UPLOADED_NFTS_FAIL";

export const UPLOAD_CREATE_BID = "UPLOAD_CREATE_BID";
export const UPLOAD_CREATE_BID_FAIL = "UPLOAD_CREATE_BID_FAIL";
export const UPLOAD_CREATE_BID_SUCCESS = "UPLOAD_CREATE_BID_SUCCESS";

export const GET_NFT_BID_SUCCESS = "GET_NFT_BID_SUCCESS";
export const GET_NFT_BID_FAIL = "GET_NFT_BID_FAIL";
export const UPDATE_NOTIFY_LIST = "UPDATE_NOTIFY_LIST";
export const MARK_ALL_NOTIFIES_AS_READ = "MARK_ALL_NOTIFIES_AS_READ";
export const GET_NOTIFIES_BY_FILTERS = "GET_NOTIFIES_BY_FILTERS";


export const SET_AVAX_PRICE = "SET_AVAX_PRICE";
export const SET_THEME_THEME = "SET_THEME_THEME";
